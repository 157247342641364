import React from 'react';

const OutlineStar = ({ stroke, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6813 5.16651C11.8326 4.9445 12.1674 4.9445 12.3187 5.16651L14.574 8.47606C14.6249 8.55063 14.7016 8.6049 14.7901 8.62891L18.7199 9.69459C18.9835 9.76607 19.087 10.0761 18.9169 10.2848L16.381 13.3959C16.3238 13.466 16.2945 13.5538 16.2984 13.6432L16.4718 17.6114C16.4835 17.8776 16.2126 18.0692 15.9562 17.9762L12.1335 16.5894C12.0474 16.5581 11.9526 16.5581 11.8665 16.5894L8.04384 17.9762C7.78741 18.0692 7.51653 17.8776 7.52816 17.6114L7.70155 13.6432C7.70546 13.5538 7.67616 13.466 7.61902 13.3959L5.08312 10.2848C4.91301 10.0761 5.01648 9.76607 5.2801 9.69459L9.20987 8.62891C9.29842 8.6049 9.37513 8.55063 9.42595 8.47606L11.6813 5.16651Z"
        fill="currentColor"
        stroke={stroke || 'white'}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default OutlineStar;
