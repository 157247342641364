import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import curry from 'ramda/es/curry';
import filter from 'ramda/es/filter';
import ifElse from 'ramda/es/ifElse';
import isEmpty from 'ramda/es/isEmpty';
import compose from 'ramda/es/compose';
import identity from 'ramda/es/identity';
import complement from 'ramda/es/complement';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

import useFilters from './useFilters';
import { loadPortfolios } from '../state/portfolios/actions';
import updateUrl from '../utils/updateUrl';

const mapSelects = map(({ slug, name }) => ({ value: slug, label: name }));

const filterEmpty = complement(isEmpty);
const transformArray = ifElse(
  Array.isArray,
  map(item => {
    if (isObject(item)) {
      return prop('value')(item);
    }
    return item;
  }),
  identity,
);
const normalizeFilters = compose(map(transformArray), filter(filterEmpty));

export default function useSearchFilters() {
  const dispatch = useDispatch();
  const { categories, tools: possibleTools, styles: possibleStyles } = useFilters();

  const {
    query: {
      category = '',
      city = '',
      country = '',
      state = '',
      styles = '',
      tools = '',
      grades = '',
    },
  } = useRouter();

  const toolOptions = useMemo(() => mapSelects(possibleTools), [possibleTools]);
  const styleOptions = useMemo(() => mapSelects(possibleStyles), [possibleStyles]);

  const toolsSelected = useMemo(() => {
    if (!tools) return [];

    const toolSlugs = tools.split(',');

    return toolOptions.filter(({ value }) => toolSlugs.includes(value));
  }, [toolOptions, tools]);

  const stylesSelected = useMemo(() => {
    if (!styles) return [];

    const styleSlugs = styles.split(',');

    return styleOptions.filter(({ value }) => styleSlugs.includes(value));
  }, [styleOptions, styles]);

  const [gradesSelected, setGrades] = useState(grades ? grades.split(',') : []);
  useEffect(() => {
    if (!category) {
      setGrades([]);
      return;
    }
    if (grades) {
      const gradeSlugs = grades.split(',');
      setGrades(gradeSlugs);
    } else {
      setGrades([]);
    }
  }, [category, grades]);

  const [stateSelects, setStateSelects] = useState({
    city,
    state,
    country,
    tools: toolsSelected,
    styles: stylesSelected,
  });

  const normalizedState = useMemo(() => normalizeFilters({ ...stateSelects }), [stateSelects]);
  const categoryFilters = useMemo(() => {
    if (!category) {
      return {};
    }
    if (!isEmpty(gradesSelected)) {
      return { categories: [{ [category]: gradesSelected }] };
    }
    return { categories: [category] };
  }, [category, gradesSelected]);

  const filters = useMemo(() => ({ ...normalizedState, ...categoryFilters }), [
    categoryFilters,
    normalizedState,
  ]);

  const prevFiltersRef = useRef();
  useEffect(() => {
    if (isEqual(prevFiltersRef.current, filters)) {
      return;
    }

    prevFiltersRef.current = filters;
    dispatch(loadPortfolios({ ...filters, isFilterRequest: true }));
  }, [dispatch, filters]);

  const onChange = useCallback(
    filtersSelected => {
      updateUrl({ ...stateSelects, ...filtersSelected, grades: gradesSelected });
      setStateSelects(prevState => ({ ...prevState, ...filtersSelected }));
    },
    [gradesSelected, stateSelects],
  );

  const handleFiltersChange = useCallback(
    curry((type, payload) => onChange({ ...stateSelects, [type]: payload || [] })),
    [onChange, stateSelects],
  );
  const handleChangeLocation = useCallback(
    ({ city = '', country = '', state = '' } = {}) =>
      onChange({ ...stateSelects, city, country, state }),
    [onChange, stateSelects],
  );
  const handleGradesChange = useCallback(
    grades => {
      updateUrl({ ...stateSelects, grades });
      setGrades(grades);
    },
    [stateSelects],
  );

  const query = useMemo(() => {
    const keys = Object.keys(normalizedState);
    // собираем объект с сджойненными через запятую массивами,
    // так как NextLink джойнит массивы значений через слэш
    // и при переходе по ссылке на другой скилл терялись значения фильтров
    const mapped = keys.reduce(
      (acc, key) => {
        const value = normalizedState[key];
        if (isEmpty(value)) {
          return acc;
        }

        if (isString(value)) {
          return { ...acc, [key]: value };
        }

        return { ...acc, [key]: value.join(',') };
      },
      { grades: isEmpty(gradesSelected) ? undefined : gradesSelected.join(',') },
    );
    return mapped;
  }, [gradesSelected, normalizedState]);

  return {
    filters,
    category,
    categories,
    toolOptions,
    styleOptions,
    handleFiltersChange,
    handleChangeLocation,
    handleGradesChange,
    query,
    gradesSelected,
    ...stateSelects,
  };
}
