import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import useAuth from 'features/auth/hooks/useAuth';
import { getRole, Roles } from 'features/auth/utils/roles';
import { favoriteArtistsSelector, isFavoriteSelector } from '../state/favoriteArtists/selectors';
import {
  loadFavoriteArtists as load,
  setFavorite as updateStateInFavoriteArtists,
} from '../state/favoriteArtists';
import {
  loadIsFavoriteArtist,
  updateFavoriteState as updateFavoriteStateInPortfolio,
} from 'features/artBuilder/state/modules/landing/actions';
import { updateFavoriteState as updateFavoriteStateInPortfolios } from 'features/rfp/state/portfolios/actions';

const useFavoriteArtists = () => {
  const dispatch = useDispatch();
  const { roles, token } = useAuth();
  const dataSlice = useSelector(favoriteArtistsSelector);
  const { isLoading, portfolios } = dataSlice;

  const role = getRole(roles);
  const isCompany = role === Roles.Company;

  const loadFavoriteArtists = useCallback(
    (params = {}) => {
      if (isCompany) {
        dispatch(load(params));
      }
    },
    [dispatch, isCompany],
  );

  const checkFavorite = useCallback(
    domain => {
      if (isCompany) {
        dispatch(loadIsFavoriteArtist(domain, token));
      }
    },
    [dispatch, isCompany, token],
  );
  const isFavorite = useSelector(isFavoriteSelector);

  const toggleFavorite = useCallback(
    (domain, isFavorite, src) => {
      if (!isCompany) {
        return;
      }
      if (src === 'favorite-artists') {
        dispatch(updateStateInFavoriteArtists({ domain, isFavorite }));
      }
      if (src === 'main') {
        dispatch(updateFavoriteStateInPortfolios(domain, isFavorite));
      }
      if (src === 'portfolio') {
        dispatch(updateFavoriteStateInPortfolio(domain, isFavorite, token));
      }
    },
    [dispatch, isCompany, token],
  );

  return { isLoading, loadFavoriteArtists, portfolios, checkFavorite, isFavorite, toggleFavorite };
};

export default useFavoriteArtists;
