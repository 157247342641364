import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import uniqueId from 'lodash/uniqueId';

import styles from './styles.styl';
import bemCN from 'utils/bemCN';
import Button from 'features/rfp/ui/Button';
import { buttonThemePropType } from 'features/rfp/ui/Button/Button';

const el = bemCN(styles)('PlanItem');

const Features = ({ features = [], title, bottom, theme }) => {
  if (Array.isArray(features) && features.length) {
    return (
      <div className={el('features', { theme, bottom })}>
        {title && <p>{title}</p>}

        <ul>
          {features.map(feature => (
            <li key={uniqueId()}>{feature}</li>
          ))}
        </ul>
      </div>
    );
  } else if (title) {
    return (
      <div className={el('features', { theme, bottom })}>
        <p>{title}</p>
      </div>
    );
  }
  {
    return null;
  }
};

const PlanItem = ({
  title,
  description,
  features,
  price,
  action,
  onAction = () => {},
  className,
  discount,
  theme = 'white',
  tariffs,
  featuresTitle,
  featuresBottom,
  actionBtnTheme = 'black',
  textColor,
  extra,
  extraTop,
}) => {
  return (
    <div className={cn(el({ theme }), className)}>
      <div className={el('row')}>
        <div>
          <p className={el('description', { theme })}>{description}</p>
          <h3 className={cn(el('title'), el('text'))}>{title}</h3>
        </div>
        <div className={el('priceSection')}>
          {tariffs && tariffs}
          <div className={cn(el('price'), el('text', { color: textColor }))}>{price}</div>
          {discount && discount}
        </div>
      </div>
      {!featuresBottom && (
        <Features features={features} bottom={featuresBottom} theme={theme} title={featuresTitle} />
      )}
      {extraTop && <div className={el('extra-top')}>{extra}</div>}
      <Button
        onClick={onAction}
        theme={actionBtnTheme}
        className={el('action-btn', { color: textColor })}
        isUppercase={false}
      >
        {action}
      </Button>
      {!extraTop && extra}
      {featuresBottom && (
        <Features features={features} bottom={featuresBottom} theme={theme} title={featuresTitle} />
      )}
    </div>
  );
};

export const planThemePropType = PropTypes.oneOf(['white', 'black']);
export const planTextColorPropType = PropTypes.oneOf(['blue', 'green']);

export const planPropTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  tariffs: PropTypes.node,
  featuresTitle: PropTypes.node,
  features: PropTypes.arrayOf(PropTypes.string),
  price: PropTypes.node,
  action: PropTypes.node,
  onAction: PropTypes.func,
  className: PropTypes.string,
  theme: planThemePropType,
  discount: PropTypes.node,
  textColor: planTextColorPropType,
  featuresBottom: PropTypes.bool,
  actionBtnTheme: buttonThemePropType,
  extra: PropTypes.node,
  extraTop: PropTypes.bool,
};

PlanItem.propTypes = planPropTypes;

export default PlanItem;
