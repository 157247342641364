import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Select from 'ui/Select';
import bemCN from 'utils/bemCN';
import LocationInput from 'ui/LocationInput';
import styles from './styles.styl';

const el = bemCN(styles)('SelectFiltersPanel');

const SelectFiltersPanel = ({
  tools,
  styles,
  location,
  toolOptions,
  styleOptions,
  handleChangeSelects,
  handleChangeLocation,
}) => (
  <div className={el()}>
    <Select
      isMulti
      name="styles"
      value={styles}
      placeholder="Styles"
      options={styleOptions}
      className={el('filter')}
      onChange={handleChangeSelects('styles')}
    />
    <Select
      isMulti
      name="tools"
      value={tools}
      placeholder="Tools"
      options={toolOptions}
      className={el('filter')}
      onChange={handleChangeSelects('tools')}
    />
    <LocationInput
      {...location}
      name="location"
      placeholder="Location"
      className={el('filter')}
      onChange={handleChangeLocation}
      inputClassName={el('location-input')}
    />
  </div>
);

const optionType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
);

SelectFiltersPanel.propTypes = {
  tools: optionType,
  styles: optionType,
  toolOptions: optionType,
  styleOptions: optionType,
  location: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }),
  handleChangeSelects: PropTypes.func,
  handleChangeLocation: PropTypes.func,
};

export default memo(SelectFiltersPanel);
