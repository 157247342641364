const MENU_CLASS = 'menu-settings__content';

export const scrollToBlockById = id => {
  const element = document.getElementById(id);
  if (element !== null) {
    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
};

export const twinkleElement = (index, elementClass = MENU_CLASS) => {
  const elements = document.getElementsByClassName(elementClass);
  if (!elements || elements.length !== index + 1) {
    return false;
  }
  const element = elements[index];
  element.classList.add(`${elementClass}--twinkle`);
  setTimeout(() => {
    element.classList.remove(`${elementClass}--twinkle`);
  }, 300);
  return true;
};

export const findAllComponentByBlockAndComponentType = (blocks, blockType, componentType) => {
  const finedBlocks = blocks.filter(block => block.module === blockType);
  if (finedBlocks == null) {
    return [];
  }
  return finedBlocks
    .map(block => block.components.find(component => component.type === componentType))
    .filter(item => item != null);
};

export const handleInputUrlSocialEmbed = link => {
  if (!/^https?:\/\/(.)+/.test(link)) {
    return `https://${link}`;
  }
  return link;
};

export const removeHtmlTagsFromString = (string = '') => string.replace(/<[^>]*>/gi, '');

export const removeSpaceAndLower = (string = '') => string.replace(/\s/g, '').toLowerCase();

export const withSlash = str => (str.endsWith('/') ? str : `${str}/`);

export const toImageObjects = sources => sources.map(src => ({ url: { src, original: src } }));
