import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import Slider from 'components/Slider';
import RightArrowIcon from 'features/rfp/ui/icons/RightArrowIcon';
import LeftArrowIcon from 'features/rfp/ui/icons/LeftArrowIcon';
import { renderCategories, el, categoriesPropTypes } from './CategoryFiltersPanel';

const ArrowButton = ({ direction, onClick, hide }) => {
  if (hide) return null;

  const icons = {
    right: RightArrowIcon,
    left: LeftArrowIcon,
  };
  const ArrowIcon = icons[direction];

  return (
    <button className={el('arrow-button', { [direction]: true })} type="button" onClick={onClick}>
      <ArrowIcon className={el('arrow-icon')} />
    </button>
  );
};

ArrowButton.propTypes = {
  direction: PropTypes.oneOf(['right', 'left']).isRequired,
  onClick: PropTypes.func,
  hide: PropTypes.bool,
};

const CategoriesSlider = ({ activeCategory, categories, query }) => {
  const [hideNextButton, setHideNextButton] = useState(false);
  const [hidePrevButton, setHidePrevButton] = useState(true);

  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (!swiper) return;
    const activeCategoryIndex = categories.findIndex(c => c.slug === activeCategory);

    swiper.on('init', () => {
      swiper.slideTo(activeCategoryIndex);
    });
    swiper.on('progress', () => {
      setHideNextButton(swiper.isEnd);
      setHidePrevButton(swiper.isBeginning);
    });
    swiper.init();
  }, [activeCategory, categories, swiper]);

  const step = 3;
  const goForward = useCallback(() => {
    if (swiper) {
      swiper.slideTo(swiper.activeIndex + step);
    }
  }, [swiper]);

  const goBack = useCallback(() => {
    if (swiper) {
      swiper.slideTo(swiper.activeIndex - step);
    }
  }, [swiper]);

  const renderNextButton = useCallback(
    () => <ArrowButton onClick={goForward} hide={hideNextButton} direction="right" />,
    [hideNextButton, goForward],
  );
  const renderPrevButton = useCallback(
    () => <ArrowButton onClick={goBack} hide={hidePrevButton} direction="left" />,
    [hidePrevButton, goBack],
  );

  const sliderParams = {
    containerClass: `swiper-container ${el('swiper-container')}`,
    slidesPerView: 'auto',
    grabCursor: true,
    centeredSlides: false,
    freeMode: true,
    shouldSwiperUpdate: true,
    init: false,
    preventClicks: false,
    renderNextButton,
    renderPrevButton,
    navigation: {
      nextEl: el('arrow-button', { right: true }),
      prevEl: el('arrow-button', { left: true }),
    },
    mousewheel: {
      invert: true,
      forceToAxis: true,
    },
  };
  return (
    <Slider {...sliderParams} getSwiper={setSwiper} className={el()}>
      {renderCategories({ categories, activeCategory, query })}
    </Slider>
  );
};

CategoriesSlider.propTypes = categoriesPropTypes;

export default CategoriesSlider;
