import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
// Context
import BackgroundImage from 'ui/BackgroundImage';
import { default as ImageComponent } from 'ui/Image';
// Components
import { useImage } from 'hooks/useMedia';
// hooks
import useLazyLoad from 'hooks/useLazyLoad';

// eslint-disable-next-line no-unused-vars
function Image({ isBg, image: data, className, isIcon, ...other }) {
  const { src, src2x, current, load, isLoaded } = useImage(data);

  const { ref } = useLazyLoad(load);
  const checkSrc = useCallback(src => (isLoaded ? src : undefined), [isLoaded]);

  return (
    <>
      {isBg ? (
        <BackgroundImage
          ref={ref}
          backgroundImage={checkSrc(current)}
          className={className}
          {...other}
        />
      ) : (
        <ImageComponent
          ref={ref}
          src={checkSrc(src)}
          src2x={checkSrc(src2x)}
          className={className}
          {...other}
        />
      )}
    </>
  );
}

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isBg: PropTypes.bool,
  isIcon: PropTypes.bool,
  onLoad: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
};

Image.defaultProps = {
  image: '',
  isIcon: false,
};

export default Image;
