import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import bemCN from 'utils/bemCN';
import Button from 'features/rfp/ui/Button';
import { RoutesRFP } from 'config/routes';
import NextLink from 'components/NextLink';
import CategoriesMenu from './CategoriesMenu';
import CategoriesSlider from './CategoriesSlider';
import RFPbreakpoints from 'config/RFPbreakpoints';

import styles from './styles.styl';

export const el = bemCN(styles)('CategoryFiltersPanel');

export const renderCategories = ({ categories, activeCategory, onClick = () => {}, query }) =>
  categories.map(({ id, name, slug }) => {
    const linkProps = {
      params: { ...query, ...(slug ? { category: slug } : {}) },
      route: slug ? RoutesRFP.Category : RoutesRFP.Home,
      className: el('link'),
    };
    return (
      <NextLink key={id} {...linkProps}>
        <Button
          className={el('button', { active: activeCategory === slug })}
          isUppercase={false}
          onClick={onClick}
        >
          {name}
        </Button>
      </NextLink>
    );
  });

export const categoriesPropTypes = {
  categories: PropTypes.array,
  activeCategory: PropTypes.string,
  query: PropTypes.objectOf(PropTypes.string),
};

const CategoryFiltersPanel = ({ category = '', categoryList = [], query }) => {
  const categories = useMemo(() => [{ id: 0, name: 'All skills', slug: '' }, ...categoryList], [
    categoryList,
  ]);

  const innerWidth = useSelector(state => state.responsive.innerWidth);

  const CategoriesComponent = useMemo(
    () =>
      !innerWidth || innerWidth >= RFPbreakpoints.verticalTablet
        ? CategoriesSlider
        : CategoriesMenu,
    [innerWidth],
  );

  return <CategoriesComponent query={query} categories={categories} activeCategory={category} />;
};

CategoryFiltersPanel.propTypes = {
  query: PropTypes.object,
  category: PropTypes.string,
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
};

export default CategoryFiltersPanel;
