import isEmpty from 'lodash/isEmpty';
import { authSelector } from 'features/auth/state/auth/selectors';
import types from './types';
import {
  sessionStart,
  getStructure,
  saveApplication,
  deleteApplication,
  saveLandingValue,
  publicationLanding,
  addLanguageToLanding,
  deleteLanguageFromLanding,
  getPublishedLanding,
  updateFavorite,
} from '../../../utils/api';

import { initBlocks } from '../blocks/actions';
import { initPages } from '../pages';

export const initLanding = landingData => {
  const landing = { ...landingData };
  delete landing.pages;

  return {
    type: types.INIT_LANDING,
    landing,
  };
};

export const initMeta = meta => {
  return {
    type: types.INIT_META,
    meta,
  };
};

export const initProfile = profile => {
  return {
    type: types.INIT_PROFILE,
    profile,
  };
};

export const initPortfolio = portfolio => {
  return {
    type: types.INIT_PORTFOLIO,
    portfolio,
  };
};

export const initCompany = company => {
  return {
    type: types.INIT_COMPANY,
    company,
  };
};

export const setPreview = () => ({
  type: types.SET_PREVIEW,
  isServerSideRender: true,
});

const setIsFavorite = isFavorite => ({
  type: types.SET_IS_FAVORITE,
  isFavorite,
});

export const setError = status => ({
  type: types.SET_ERROR,
  loadStatus: status,
  error: true,
});

export const unSetError = () => ({
  type: types.UNSET_ERROR,
  loadStatus: 200,
  error: false,
});

export const startLoading = () => dispatch => {
  dispatch({
    type: types.LANDING_IS_LOAD,
    loading: true,
  });
};

export const endLoading = () => dispatch => {
  dispatch({
    type: types.END_LOADING,
    loaded: true,
    loading: false,
    isIframe: typeof window !== 'undefined' && window.self !== window.top,
  });
};

export const setLandingData = data => dispatch => {
  dispatch(initLanding(data));
  dispatch(initBlocks(data));
  dispatch(initPages(data));
  dispatch(endLoading());
};

export const serverSetLandingData = data => dispatch => {
  dispatch(initLanding(data));
  dispatch(initBlocks(data));
  dispatch(initPages(data));
  dispatch(endLoading());
};

export const publishLanding = () => async (dispatch, getState) => {
  const {
    landing: { landing },
  } = getState().artBuilderReducer;

  const { token } = authSelector(getState());

  const { domain } = landing;
  return publicationLanding({ token, domain });
};

export const updateFavoriteState = (domain, isFavorite, token) => async dispatch => {
  updateFavorite({ domain, isFavorite, token });

  dispatch(setIsFavorite(isFavorite));
};

export const loadIsFavoriteArtist = (domain, token) => async dispatch => {
  if (!domain) return false;

  const { data: { results: { is_favorite = false } = {} } = {} } =
    (await getPublishedLanding({
      domain,
      isCompanyRequest: true,
      token,
    })) || {};

  dispatch(setIsFavorite(is_favorite));
  return is_favorite;
};

export const loadPublishedLanding = domain => async dispatch => {
  const response = await getPublishedLanding({
    domain,
  });

  const {
    data: { results: { structure, url_preview, profile, portfolio, company } = {} } = {},
  } = response;
  if (isEmpty(structure)) return true;

  dispatch(initProfile(profile));
  dispatch(initPortfolio(portfolio));
  dispatch(initCompany(company));

  const artistName = `${profile.name} ${profile.surname}`;
  const url = `https://80.lv/talent/p/${domain}`;
  const meta = {
    'og-og:url': {
      type: 'property',
      name: 'og:url',
      content: url,
    },
    'og-og:image': {
      type: 'property',
      name: 'og:image',
      content: url_preview,
    },
    'og-og:image:width': {
      type: 'property',
      name: 'og:image:width',
      content: '600',
    },
    'og-og:image:height': {
      type: 'property',
      name: 'og:image:height',
      content: '315',
    },
    'og-og:title': {
      type: 'property',
      name: 'og:title',
      content: artistName,
    },
    'og-og:description': {
      type: 'property',
      name: 'og:description',
      content: 'Meet the 80 Level featured job-seeker at 80.lv/talent',
    },
    'twitter-twitter:card': {
      type: 'name',
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    'twitter-twitter:title': {
      type: 'name',
      name: 'twitter:title',
      content: artistName,
    },
    'twitter-twitter:description': {
      type: 'name',
      name: 'twitter:description',
      content: 'Meet the 80 Level featured job-seeker at 80.lv/talent',
    },
    'twitter-twitter:image': {
      type: 'name',
      name: 'twitter:image',
      content: url_preview,
    },
  };

  dispatch(initMeta(meta));

  return dispatch(setLandingData(structure));
};

export const getData = domainWindow => async dispatch => {
  try {
    const responseSession = await sessionStart({ domain: domainWindow });
    if (responseSession.status !== 200) {
      dispatch(setError(responseSession.status));
      dispatch(endLoading());
      return false;
    }
    const {
      landing: { merchantId, projectId, domain },
    } = responseSession.data;
    const responseStructure = await getStructure({ merchantId, projectId, domain });
    if (responseStructure.status !== 200) {
      dispatch(setError(responseStructure.status));
      dispatch(endLoading());
      return false;
    }

    dispatch(unSetError());
    const { data } = responseStructure;
    dispatch(setLandingData(data));

    return !!data.type;
  } catch (error) {
    return false;
  }
};

export const updateStructure = (merchantId, projectId, domain) => async dispatch => {
  try {
    const responseStructure = await getStructure({ merchantId, projectId, domain });
    if (responseStructure.status !== 200) {
      dispatch(setError(responseStructure.status));
      dispatch(endLoading());
      return false;
    }

    dispatch(unSetError());
    const { data } = responseStructure;
    dispatch(setLandingData(data));

    return !!data.type;
  } catch (error) {
    return false;
  }
};

export const changeLocale = locale => async dispatch => {
  dispatch({
    type: types.CHANGE_LOCALE,
    locale,
  });
};

export const addLanguage = language => async (dispatch, getState) => {
  const {
    landing: { landing },
  } = getState().artBuilderReducer;
  const { merchantId, projectId, domain } = landing;

  const response = await addLanguageToLanding({
    merchantId,
    projectId,
    domain,
    data: { language },
  });
  const { data, status } = response;

  if (!data || status !== 200) {
    return false;
  }

  dispatch(initBlocks(data));
  dispatch(initPages(data));

  dispatch({
    type: types.CHANGE_LANGUAGES_IN_LANDING,
    landing: data,
    locale: language,
  });
  return true;
};

export const deleteLanguage = language => async (dispatch, getState) => {
  const {
    landing: { landing },
    locale,
  } = getState().artBuilderReducer;
  const { merchantId, projectId, domain } = landing;

  const response = await deleteLanguageFromLanding({
    merchantId,
    projectId,
    domain,
    data: { language },
  });
  const { data, status } = response;
  if (!data || status !== 200) {
    return false;
  }
  const updatedLanding = { ...landing };
  updatedLanding.languages = data;
  dispatch({
    type: types.CHANGE_LANGUAGES_IN_LANDING,
    landing: { ...updatedLanding },
    locale: data.includes(locale) ? locale : data[data.length - 1],
  });
  return true;
};

export const changeApp = (type, value) => async (dispatch, getState) => {
  const {
    landing: { landing },
  } = getState().artBuilderReducer;
  const { merchantId, projectId, domain } = landing;
  const data = { type, value };

  const response = await saveApplication({
    merchantId,
    projectId,
    domain,
    data,
  });
  if (response.status !== 200) {
    return;
  }

  const updatedLanding = { ...landing };
  updatedLanding.apps[type] = value;
  dispatch({
    type: types.UPDATE_LANDING,
    landing: updatedLanding,
  });
};

export const deleteApp = type => async (dispatch, getState) => {
  const {
    landing: { landing },
  } = getState().artBuilderReducer;
  const { merchantId, projectId, domain } = landing;
  const data = { type };

  const response = await deleteApplication({
    merchantId,
    projectId,
    domain,
    data,
  });
  if (response.status !== 200) {
    return;
  }

  const updatedLanding = { ...landing };
  updatedLanding.apps[type] = '';
  dispatch({
    type: types.UPDATE_LANDING,
    landing: updatedLanding,
  });
};

export const changeSetting = (propName, propValue) => async (dispatch, getState) => {
  const {
    landing: { landing },
  } = getState().artBuilderReducer;
  const { merchantId, projectId, _id: landingId } = landing;
  const data = {
    propName,
    propValue,
  };

  const response = await saveLandingValue({
    merchantId,
    projectId,
    landingId,
    data,
  });
  if (response.status !== 200) {
    return false;
  }
  const updatedLanding = { ...landing };
  updatedLanding[propName] = propValue;
  dispatch({
    type: types.UPDATE_LANDING,
    landing: { ...updatedLanding },
  });
  return true;
};
