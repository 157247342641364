import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import styles from './styles.styl';

const cx = cn.bind(styles);

const Filler = ({
  className,
  textBlockClassName,
  imageClassName,
  title,
  description,
  actions,
  Icon,
  absoluteImage = false,
}) => {
  return (
    <div
      className={cx('Filler', className, {
        'Filler--absolute': absoluteImage,
      })}
    >
      <div
        className={cx('Filler__text_block', textBlockClassName, {
          'Filler__text_block--center': absoluteImage,
        })}
      >
        <h1 className={cx('Filler__header')}>{title}</h1>
        <p className={cx('Filler__text')}>{description}</p>
        <div className={cx('Filler__actions')}>{actions}</div>
      </div>
      <Icon
        className={cx('Filler__image', imageClassName, {
          'Filler__image--absolute': absoluteImage,
        })}
      />
    </div>
  );
};

Filler.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.node,
  Icon: PropTypes.elementType,
  absoluteImage: PropTypes.bool,
  imageClassName: PropTypes.string,
  textBlockClassName: PropTypes.string,
};

export default Filler;
