import React from 'react';
import { string } from 'prop-types';

import RFPPage from 'components/RFPPage';
import RFPMain from 'features/rfp/pages/main';

import { SetThemeByRoute } from 'hooks/useTheme';
import { loadFilters } from 'features/rfp/state/filters/actions';
import { FeatureNames, configureComponentFeatureWithErrorPage } from 'components/FeatureToggle';
import { getToken } from 'features/auth/utils/auth';

import styles from './styles.styl';

const RFP = ({ cookieToken }) => {
  SetThemeByRoute('/rfpPages');

  return (
    <RFPPage className={styles.RFPPage}>
      <RFPMain cookieToken={cookieToken} />
    </RFPPage>
  );
};

RFP.propTypes = {
  cookieToken: string,
};

RFP.features = [FeatureNames.Rfp];

RFP.getInitialProps = async ctx => {
  const {
    isServer,
    store: { dispatch },
  } = ctx;
  if (isServer) {
    await dispatch(loadFilters());
  }
  const cookieToken = getToken(ctx);
  return {
    cookieToken,
  };
};

export default configureComponentFeatureWithErrorPage(RFP.features, RFP);
