import React, { useCallback, useRef, memo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.styl';
import bemCN from 'utils/bemCN';

const el = bemCN(styles)('Tab');

const Tariffs = ({ setSelectedTariff, tabs, theme }) => {
  const wrapperRef = useRef();
  const switchCurrentTabHandler = useCallback(event => {
    wrapperRef.current.querySelectorAll('button').forEach(Button => {
      Button.classList.remove(styles.current);
    });
    event.target.classList.add(styles.current);
    setSelectedTariff(event.target.getAttribute('data-tab'));
  }, []);

  useLayoutEffect(() => {
    wrapperRef.current.querySelector('button').classList.add(styles.current);
  }, []);

  return (
    <div ref={wrapperRef} className={el('Menu', { theme })} onClick={switchCurrentTabHandler}>
      {tabs.map(tab => (
        <button type="button" key={tab.value} data-tab={tab.value} className={el('btn', { theme })}>
          {tab.text}
        </button>
      ))}
    </div>
  );
};

Tariffs.propTypes = {
  setSelectedTariff: PropTypes.string,
  theme: PropTypes.string,
  tabs: PropTypes.arrayOf({ value: PropTypes.string, text: PropTypes.string }),
};

export default memo(Tariffs);
