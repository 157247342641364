import React from 'react';

const TriangleIcon = props => (
  <svg
    {...props}
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 0H2.5C0.851909 0 -0.0888546 1.88153 0.9 3.2L5.9 9.86667C6.7 10.9333 8.3 10.9333 9.1 9.86667L14.1 3.2C15.0889 1.88153 14.1481 0 12.5 0Z"
      fill="white"
    />
  </svg>
);

export default TriangleIcon;
