import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadPortfolios as load } from '../state/portfolios/actions';
import { portfoliosSelector as selector } from '../state/portfolios/selectors';

export default function usePortfolios() {
  const dispatch = useDispatch();
  const { isLoading, items: portfolios } = useSelector(selector);
  const loadPortfolios = useCallback(params => dispatch(load(params)), [dispatch]);

  return {
    isLoading,
    portfolios,
    loadPortfolios,
  };
}
