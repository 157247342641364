import React, { forwardRef, memo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Avatar from 'components/Avatar';
import OutlineStar from 'ui/icons/OutlineStar';
import Image from 'components/Image';
import NextLink from 'components/NextLink';
import IconButton from '../IconButton';
import StudioIcon from 'ui/icons/StudioIcon';

import { RoutesRFP } from 'config/routes';
import bemCN from 'utils/bemCN';

import styles from './styles.styl';

const el = bemCN(styles)('Card');

const fullName = (name, surname) => (surname ? `${name} ${surname}` : name);

const Card = forwardRef(
  (
    {
      className = '',
      portfolio,
      profile,
      company,
      subscribed = false,
      isFavorite = false,
      onClick = () => {},
      onFavoriteClick,
    },
    ref,
  ) => {
    const { domain, preview, url_preview } = portfolio;
    const { avatar, name, surname, position } = profile;

    const handleFavoriteClick = e => {
      e.preventDefault();
      e.stopPropagation();
      onFavoriteClick(domain, !isFavorite);
    };

    const showName = subscribed;

    const CardWrapper = subscribed ? NextLink : 'div';
    const linkProps = {
      ref,
      targetBlank: true,
      params: { domain },
      route: RoutesRFP.Profile,
    };
    const wrapperProps = subscribed ? linkProps : {};

    return (
      <CardWrapper className={cn(el(), className)} onClick={onClick} {...wrapperProps}>
        <div className={el('portfolio')}>
          {company && (
            <div className={el('studio-label')}>
              <span className={el('studio-label-icon')}>
                <StudioIcon />
              </span>
              <p className={el('studio-label-text')}>Studio</p>
            </div>
          )}
          <div className={el('spacer')}>
            <div className={el('image-container')}>
              <Image className={el('image')} image={preview || url_preview || ''} isBg>
                <div className={el('gradient')} />
              </Image>
            </div>
          </div>
          <div className={el('position')}>
            <div className={el('row')}>
              <h3>{company?.specialisation || position}</h3>
              <IconButton
                className={el('fav-button', { active: isFavorite })}
                onClick={handleFavoriteClick}
              >
                <OutlineStar />
              </IconButton>
            </div>
          </div>
        </div>
        {showName && (
          <div className={el('profile')}>
            <Avatar className={el('avatar')} image={avatar} />
            <span>{company?.title || fullName(name, surname)}</span>
            {isFavorite && (
              <span className={el('fav-icon')}>
                <OutlineStar width="12" height="12" />
              </span>
            )}
          </div>
        )}
      </CardWrapper>
    );
  },
);

Card.displayName = 'Card';

const portfolioType = PropTypes.shape({
  domain: PropTypes.string.isRequired,
  preview: PropTypes.object,
  url_preview: PropTypes.string,
});

const profileType = PropTypes.shape({
  name: PropTypes.string,
  avatar: PropTypes.string,
  surname: PropTypes.string,
  position: PropTypes.string,
});

Card.propTypes = {
  className: PropTypes.string,
  profile: profileType.isRequired,
  portfolio: portfolioType.isRequired,
  subscribed: PropTypes.bool,
  isFavorite: PropTypes.bool,
  onClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
};

export default memo(Card);
