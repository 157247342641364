import React from 'react';
import curry from 'ramda/es/curry';
import { Feature } from '@paralleldrive/react-feature-toggles';
import { getCurrentActiveFeatureNames } from '@paralleldrive/feature-toggles';
import ErrorPage from 'components/ErrorPage';
import { features as initialFeatures, checkIsActiveFeature } from 'utils/features';

const configureComponentFeature = (InnactiveComponent, featureNames, FeatureComponent) => {
  const getFeatureComponentsInitialProps = ctx => {
    return FeatureComponent && FeatureComponent.getInitialProps
      ? FeatureComponent.getInitialProps(ctx)
      : {};
  };

  const getInnactiveComponentInitialProps = ctx => {
    return InnactiveComponent && InnactiveComponent.getInitialProps
      ? InnactiveComponent.getInitialProps(ctx)
      : {};
  };

  const NextComponentFeature = props => (
    <Feature>
      {({ features }) =>
        checkIsActiveFeature(featureNames, features) ? (
          <FeatureComponent {...props} />
        ) : InnactiveComponent ? (
          <InnactiveComponent {...props} />
        ) : null
      }
    </Feature>
  );

  NextComponentFeature.getInitialProps = async ctx => {
    const { query } = ctx;
    const activeFeaturesNames = getCurrentActiveFeatureNames({
      initialFeatures,
      req: { query },
    });
    const isActiveFeature = checkIsActiveFeature(featureNames, activeFeaturesNames);
    const featureComponentsInitialProps = isActiveFeature
      ? await getFeatureComponentsInitialProps(ctx)
      : await getInnactiveComponentInitialProps(ctx);
    return {
      ...featureComponentsInitialProps,
    };
  };

  return NextComponentFeature;
};

const curriedConfigureComponentFeature = curry(configureComponentFeature);

export const configureComponentFeatureWithErrorPage = curriedConfigureComponentFeature(ErrorPage);

export default curriedConfigureComponentFeature;
