import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Check from 'features/registration/ui/icons/Check';
import Tooltip from 'ui/Tooltip';

import useFilters from 'features/rfp/hooks/useFilters';
import useAnalytics from 'hooks/useAnalytics';
import bemCN from 'utils/bemCN';

import styles from './styles.styl';

const el = bemCN(styles)('GradesFiltersPanel');

const GradesFiltersPanel = ({ onChange, selectedGrades: selected = [], disabled }) => {
  const { grades } = useFilters();
  const { elementClick } = useAnalytics();

  const renderGradeFilter = useCallback(
    ({ slug, name, id }) => {
      const handleChange = ({ target: { checked } }) => {
        let newSelected = [];
        if (checked) {
          elementClick('80lv_rfp_main_filter-experience', slug);
          newSelected = [...selected, slug];
        } else {
          newSelected = selected.filter(selectedSlug => selectedSlug !== slug);
        }
        onChange(newSelected);
      };

      const checked = selected.includes(slug);

      const disabledTooltip = 'Select a skill to use the experience filter';

      return (
        <Fragment key={id}>
          <label
            key={id}
            className={cn(el('checkbox'), el('item', { disabled }))}
            data-tip={disabledTooltip}
          >
            <input
              type="checkbox"
              className={el('checkbox-input')}
              onChange={handleChange}
              checked={checked}
              disabled={disabled}
            />
            <div className={el('checkbox-filler')}>
              <Check className={el('checkbox-icon')} />
            </div>
            <span className={el('checkbox-title', { checked })}>{name}</span>
          </label>

          {disabled && <Tooltip />}
        </Fragment>
      );
    },
    [disabled, elementClick, onChange, selected],
  );

  if (isEmpty(grades)) {
    return <div className={el('container')}></div>;
  }

  return (
    <div className={el('container')}>
      <div className={el()}>{grades.map(renderGradeFilter)}</div>
    </div>
  );
};

GradesFiltersPanel.propTypes = {
  onChange: PropTypes.func,
  selectedGrades: PropTypes.array,
  disabled: PropTypes.bool,
};

export default GradesFiltersPanel;
