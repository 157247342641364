/**
 * @param sub
 * @param email
 * @param plan
 * @param [name]
 * @param [sandbox]
 * @returns {{settings: {mode: *, ui: {theme: string}, project_id, language: string}, purchase: (*|{subscription: {plan_id: *}}), user: {name: {value: *}, id: {hidden: boolean, value: *}, email: {value: *}}}}
 */
const getData = ({ project_id, sub, email, plan, name, sandbox }) => ({
  user: {
    id: {
      value: sub,
      hidden: true,
    },
    name: {
      value: name || email,
    },
    email: {
      value: email,
    },
  },
  settings: {
    project_id,
    mode: sandbox ? 'sandbox' : undefined,
    language: 'en',
    ui: {
      theme: 'dark',
    },
  },
  purchase: {
    subscription: {
      plan_id: plan,
    },
  },
});

module.exports = getData;
