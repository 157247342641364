import {
  saveBlock,
  addPageBlock,
  movePageBlock,
  deletePageBlock,
  duplicatePageBlock,
} from 'features/artBuilder/utils/api';
import Blocks from 'features/artBuilder/types/blocks';
import { moveElement } from 'features/artBuilder/utils/arrayHelper';
import { initSavingProcess, dataSaved, dataDontSave } from '../spaces';
import { scrollToBlockById, twinkleElement } from 'features/artBuilder/utils/commonHelper';

export const slideAdded = () => (dispatch, getState) => {
  const {
    blocks: { isSlideAdded },
  } = getState().artBuilderReducer;
  if (isSlideAdded) {
    dispatch({
      type: 'SLIDE_ADDED',
      isSlideAdded: false,
    });
  }
};

export const saveBlockProcess = (merchantId, projectId, landing, data) => async dispatch => {
  dispatch(initSavingProcess());

  try {
    const savedBlock = await saveBlock({
      merchantId,
      projectId,
      landing,
      data,
    });

    if (savedBlock.status >= 200 && savedBlock.status < 300) {
      dispatch(dataSaved());
      dispatch(slideAdded());
      return savedBlock.data;
    }

    dispatch(dataDontSave());
    return false;
  } catch (error) {
    dispatch(dataDontSave(error));
    return false;
  }
};

export const constUpdateBlocks = (blocks, currentBlock) => ({
  type: 'CHANGE_BLOCK_DATA',
  currentBlock,
});

const constChangeBlockByIndex = (blockIndex, currentBlock) => ({
  type: 'CHANGE_BLOCK_BY_INDEX',
  currentBlock,
  blockIndex,
});

export const initBlocks = landing => dispatch => {
  const blocks = [];
  landing.pages.forEach(page => {
    page.blocks.forEach(block => {
      if (block === Blocks.HERO) {
        blocks.unshift(block);
      } else {
        blocks.push(block);
      }
    });
  });

  dispatch({
    type: 'INIT_BLOCKS_DATA',
    blocks,
  });
};

export const closeBlockSettings = () => (dispatch, getState) => {
  const {
    blocks: { isActiveEditor },
  } = getState().artBuilderReducer;

  if (isActiveEditor) {
    dispatch({
      type: 'CLOSE_BLOCK_SETTINGS',
      isActiveEditor: false,
    });
  }
};

export const closeAllSettings = () => dispatch => {
  dispatch(closeBlockSettings());
};

export const slideAdding = () => dispatch => {
  dispatch({
    type: 'SLIDE_ADDING',
    isSlideAdded: true,
  });
};

export const scrollBlock = nextBlockId => (dispatch, getState) => {
  const {
    blocks: { blocks },
  } = getState().artBuilderReducer;
  const scrolledBlock = blocks.find(block => block._id === nextBlockId);

  if (scrolledBlock && scrolledBlock._id) {
    dispatch({
      type: 'SCROLL_BLOCK',
      scrolledBlock,
    });
  }
};

export const checkoutBlock = nextBlockId => (dispatch, getState) => {
  const { blocks } = getState().artBuilderReducer;
  dispatch({
    type: 'CHECKOUT_BLOCK',
    currentBlock: blocks.blocks.find(block => block._id === nextBlockId),
    isActiveEditor: true,
  });
  twinkleElement(0);
};

export const checkoutBlockByComponent = (nextBlockId, componentIndex) => (dispatch, getState) => {
  const { blocks } = getState().artBuilderReducer;

  dispatch({
    type: 'CHECKOUT_BLOCK_BY_COMPONENT',
    currentBlock: blocks.blocks.find(block => block._id === nextBlockId),
    isActiveEditor: true,
    component: componentIndex,
  });

  twinkleElement(0);
};

export const changeCurrentBlock = currentBlock => async (dispatch, getState) => {
  const {
    blocks: { blocks },
  } = getState().artBuilderReducer;
  const { merchantId, projectId, landing } = currentBlock;
  const blockIndex = blocks.findIndex(el => el._id === currentBlock._id);
  dispatch({
    type: 'CHANGE_CURRENT_BLOCK',
    currentBlock,
    blockIndex,
  });

  return dispatch(saveBlockProcess(merchantId, projectId, landing, currentBlock));
};

export const changeValue = (prop, value, needSave = true) => async (dispatch, getState) => {
  const { blocks } = getState().artBuilderReducer;
  const currentBlock = { ...blocks.currentBlock };
  const { merchantId, projectId, landing } = currentBlock;

  currentBlock.values[prop] = value;
  dispatch(constUpdateBlocks(blocks, currentBlock));

  if (needSave) {
    return dispatch(saveBlockProcess(merchantId, projectId, landing, currentBlock));
  }
  return true;
};

export const changeComponent = ({ index, prop, value, needSave = true, scroll = false }) => async (
  dispatch,
  getState,
) => {
  const { blocks } = getState().artBuilderReducer;
  const currentBlock = { ...blocks.currentBlock };
  const { merchantId, projectId, landing, module } = currentBlock;
  currentBlock.components[index][prop] = value;
  dispatch(constUpdateBlocks(blocks, currentBlock));

  if (scroll) {
    scrollToBlockById(`${module}-${currentBlock._id}`);
  }

  if (needSave) {
    return dispatch(saveBlockProcess(merchantId, projectId, landing, currentBlock));
  }
  return true;
};

export const addComponent = component => async (dispatch, getState) => {
  const { blocks } = getState().artBuilderReducer;
  const currentBlock = { ...blocks.currentBlock };
  const { merchantId, projectId, landing } = currentBlock;

  const blockIndex = blocks.blocks.findIndex(el => el._id === currentBlock._id);
  currentBlock.components.push(component);
  const savedBlockResult = await dispatch(
    saveBlockProcess(merchantId, projectId, landing, currentBlock),
  );

  return savedBlockResult ? dispatch(constChangeBlockByIndex(blockIndex, savedBlockResult)) : false;
};

export const removeComponent = index => async (dispatch, getState) => {
  const { blocks } = getState().artBuilderReducer;
  const currentBlock = { ...blocks.currentBlock };
  const { merchantId, projectId, landing } = currentBlock;

  const blockIndex = blocks.blocks.findIndex(el => el._id === currentBlock._id);
  currentBlock.components.splice(index, 1);
  const savedBlockResult = await dispatch(
    saveBlockProcess(merchantId, projectId, landing, currentBlock),
  );

  return savedBlockResult ? dispatch(constChangeBlockByIndex(blockIndex, savedBlockResult)) : false;
};

export const changeBlockById = ({ blockId, propName, propValue }) => (dispatch, getState) => {
  const { blocks } = getState().artBuilderReducer;
  const key = blocks.blocks.findIndex(el => el._id === blockId);
  const updatedBlock = {
    ...blocks.blocks[key],
    [propName]: propValue,
  };
  dispatch({
    type: 'CHANGE_BLOCK_BY_ID',
    blockId,
    updatedBlock,
  });
};

export const deleteBlock = blockId => async (dispatch, getState) => {
  const {
    blocks: { blocks },
    landing: { landing },
    pages: { pages, currentPage },
  } = getState().artBuilderReducer;
  const pageId = pages[currentPage]._id;
  const { merchantId, projectId, _id: landingId } = landing;
  const updatedBlocks = blocks.filter(block => block._id !== blockId);

  dispatch(initSavingProcess());

  const deletedBlock = await deletePageBlock({
    merchantId,
    projectId,
    landingId,
    pageId,
    data: { blockId },
  });

  if (deletedBlock.status >= 200 && deletedBlock.status < 400) {
    dispatch({
      type: 'DELETE_BLOCK',
      blocks: updatedBlocks,
      isActiveEditor: false,
    });

    dispatch(dataSaved());
    return true;
  }

  dispatch(dataDontSave());
  return false;
};

export const duplicateBlock = () => async (dispatch, getState) => {
  const {
    blocks: { currentBlock, blocks },
    landing: { landing },
    pages: { pages, currentPage },
  } = getState().artBuilderReducer;
  const pageId = pages[currentPage]._id;
  const { merchantId, projectId, _id: landingId } = landing;
  const blockId = currentBlock._id;

  dispatch(initSavingProcess());

  const { data: duplicatedBlock, status } = await duplicatePageBlock({
    merchantId,
    projectId,
    landingId,
    pageId,
    data: { blockId },
  });

  if (status >= 200 && status < 400 && duplicatedBlock) {
    setImmediate(() => {
      scrollToBlockById(`${duplicatedBlock.module}-${duplicatedBlock._id}`);
    });
    const updatedBlocks = [...blocks];
    const currentBlockIndex = blocks.findIndex(({ _id }) => _id === blockId);
    updatedBlocks.splice(currentBlockIndex + 1, 0, duplicatedBlock);

    dispatch({
      type: 'DUPLICATE_BLOCK',
      blocks: updatedBlocks,
    });

    dispatch(dataSaved());
    dispatch(checkoutBlock(duplicatedBlock._id));
    return true;
  }

  dispatch(dataDontSave());
  return false;
};

export const moveBlock = (source, destination) => (dispatch, getState) => {
  const {
    blocks: { blocks },
    landing: { landing },
    pages: { pages, currentPage },
  } = getState().artBuilderReducer;
  const reorderedBlocks = moveElement(blocks, source, destination);
  const pageId = pages[currentPage]._id;
  const { merchantId, projectId, _id: landingId } = landing;
  const data = { source, destination };

  dispatch(initSavingProcess());

  dispatch({
    type: 'MOVE_BLOCK',
    blocks: reorderedBlocks,
  });
  movePageBlock({
    merchantId,
    projectId,
    landingId,
    pageId,
    data,
  })
    .then(dispatch(dataSaved()))
    .catch(error => dispatch(dataDontSave(error)));
};

export const addBlock = (block, index) => async (dispatch, getState) => {
  const {
    blocks: { blocks },
    landing: { landing },
    pages: { pages, currentPage },
  } = getState().artBuilderReducer;
  const pageId = pages[currentPage]._id;
  const { merchantId, projectId, _id: landingId } = landing;
  const data = { block, index };

  dispatch(initSavingProcess());

  const { data: newBlock, status } = await addPageBlock({
    merchantId,
    projectId,
    landingId,
    pageId,
    data,
  });

  if (status >= 200 && status < 400 && newBlock) {
    const updatedBlocks = [...blocks];
    updatedBlocks.splice(index, 0, newBlock);

    dispatch({
      type: 'ADD_BLOCK',
      blocks: updatedBlocks,
    });

    dispatch(dataSaved());
    dispatch(checkoutBlock(newBlock._id));
    return newBlock;
  }

  dispatch(dataDontSave());
  return false;
};

export const changeBlockValueForId = (blockId, propName, propValue) => async (
  dispatch,
  getState,
) => {
  const {
    blocks: { blocks },
  } = getState().artBuilderReducer;
  const block = blocks.find(el => el._id === blockId);
  const values = { ...block.values, [propName]: propValue };
  const updatedBlock = { ...block, values };
  const { merchantId, projectId, landing } = block;

  const blockData = await dispatch(saveBlockProcess(merchantId, projectId, landing, updatedBlock));

  if (blockData) {
    dispatch(changeBlockById({ blockId, propName: 'values', propValue: values }));
  }
};

export const changeBlockValuesForId = (blockId, valuesUpdated) => async (dispatch, getState) => {
  const {
    blocks: { blocks },
  } = getState().artBuilderReducer;
  const block = blocks.find(el => el._id === blockId);
  const values = { ...block.values, ...valuesUpdated };
  const updatedBlock = { ...block, values };
  const { merchantId, projectId, landing } = block;

  const blockData = await dispatch(saveBlockProcess(merchantId, projectId, landing, updatedBlock));

  if (blockData) {
    dispatch(changeBlockById({ blockId, propName: 'values', propValue: values }));
  }
};
