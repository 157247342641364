import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';

import Container from 'components/Container';
import PortfolioList from '../PortfolioList';
import CompanyFiller from '../MainPageFillers/CompanyFiller';
import PaywallPopup from '../../features/company/components/PaywallPopup';
import SelectFiltersPanel from '../SelectFiltersPanel';
import CategoryFiltersPanel from '../CategoryFiltersPanel';
import Paywall from '../Paywall';
import GradesFiltersPanel from '../GradesFiltersPanel';
import NotificationMark from 'ui/icons/NotificationMark';

import useSearchFilters from '../../hooks/useSearchFilters';
import useCompanyInfo from '../../features/company/hooks/useCompanyInfo';
import useJobCreationContext from 'features/rfp/features/company/hooks/useJobCreationContext';
import { unlockScroll } from 'utils/scrollLock';
import useModal from 'hooks/useModal';
import usePortfolios from 'features/rfp/hooks/usePortfolios';
import useFavoriteArtists from 'features/rfp/features/company/hooks/useFavoriteArtists';
import useAnalytics from 'hooks/useAnalytics';
import bemCN from 'utils/bemCN';

import styles from './styles.styl';

const el = bemCN(styles)('CompanyMainPage');

const NotificationBlock = ({ children }) => {
  return (
    <div className={el('notification-block')}>
      <span className={el('notification-icon')}>
        <NotificationMark />
      </span>
      <p className={el('notification-text')}>{children}</p>
    </div>
  );
};

const CompanyMainPage = ({ className }) => {
  const { subscribed, isLoaded: isCompanyInfoLoaded } = useCompanyInfo();

  const {
    openModal: openPaywallModal,
    closeModal: closePaywallModal,
    Modal: PaywallModalWrapper,
    isOpen: isPaywallModalOpen,
    ref: paywallModalRef,
  } = useModal();

  const { elementClick } = useAnalytics();
  const handleSubscribeClick = () => {
    elementClick('80lv_rfp_main_subscribe');
    openPaywallModal();
  };

  const router = useRouter();
  // need because links from modals with fixed scroll
  useEffect(() => {
    const handleRouteChange = () => {
      unlockScroll();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const {
    openJobCreationModal,
    closeJobCreationModal,
    isJobCreationModalOpen,
  } = useJobCreationContext();

  useEffect(() => {
    if (router.query.action === 'create-job') {
      openJobCreationModal();
    } else if (isJobCreationModalOpen) {
      closeJobCreationModal();
    }
  }, [closeJobCreationModal, isJobCreationModalOpen, openJobCreationModal, router.query.action]);

  const {
    tools,
    query,
    styles,
    filters,
    category,
    categories,
    toolOptions,
    styleOptions,
    gradesSelected,
    handleFiltersChange,
    handleChangeLocation,
    handleGradesChange,
    ...location
  } = useSearchFilters();

  const { portfolios, loadPortfolios } = usePortfolios();
  const { toggleFavorite } = useFavoriteArtists();
  const handleFavoriteClick = (domain, isFavorite) => toggleFavorite(domain, isFavorite, 'main');

  const showNotificationBlock = useMemo(() => !isEmpty(gradesSelected), [gradesSelected]);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <CategoryFiltersPanel category={category} categoryList={categories} query={query} />
      <GradesFiltersPanel
        onChange={handleGradesChange}
        selectedGrades={gradesSelected}
        disabled={isEmpty(category)}
      />
      <Container className={className}>
        <CompanyFiller
          onSubscribe={handleSubscribeClick}
          subscribed={subscribed}
          isCompanyInfoLoaded={isCompanyInfoLoaded}
        />
        <SelectFiltersPanel
          tools={tools}
          styles={styles}
          location={location}
          toolOptions={toolOptions}
          styleOptions={styleOptions}
          handleChangeSelects={handleFiltersChange}
          handleChangeLocation={handleChangeLocation}
        />
        {showNotificationBlock && (
          <NotificationBlock>
            Experience (junior/middle/senior/lead) is our newest profile attribute – some job-seeker
            profiles may not reflect experience for all skills. We recommend doing a search with the
            filter on, view results, then search with the filter off.
          </NotificationBlock>
        )}
        {isCompanyInfoLoaded && (
          <>
            <PortfolioList
              portfolios={portfolios}
              loadPortfolios={loadPortfolios}
              filters={filters}
              subscribed={subscribed}
              showSubscriptionPopup={openPaywallModal}
              preventLoadMore={!subscribed}
              onFavoriteClick={handleFavoriteClick}
            />
            {!subscribed && <Paywall onClick={handleSubscribeClick} />}
          </>
        )}

        <div ref={paywallModalRef}>
          {isPaywallModalOpen && (
            <PaywallModalWrapper>
              <PaywallPopup closeModal={closePaywallModal} />
            </PaywallModalWrapper>
          )}
        </div>
      </Container>
    </div>
  );
};

CompanyMainPage.propTypes = {
  className: PropTypes.string,
};

export default CompanyMainPage;
