import React from 'react';

const RightArrowIcon = props => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="#000"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7071 10.2929C15.0976 10.6834 15.0976 11.3166 14.7071 11.7071L8.70711 17.7071C8.31658 18.0976 7.68342 18.0976 7.29289 17.7071C6.90237 17.3166 6.90237 16.6834 7.29289 16.2929L12.5858 11L7.29289 5.70711C6.90237 5.31658 6.90237 4.68342 7.29289 4.29289C7.68342 3.90237 8.31658 3.90237 8.70711 4.29289L14.7071 10.2929Z"
    />
  </svg>
);

export default RightArrowIcon;
