import React from 'react';

const SubtractIcon = props => {
  return (
    <svg
      width="360"
      height="360"
      viewBox="0 0 360 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M180 35.3916L174.558 29.9494C136.456 -8.15233 75.7659 -9.8989 35.5932 24.7088L102.253 91.3691C145.192 134.307 214.809 134.307 257.747 91.3691L268.631 102.254C225.693 145.191 225.693 214.809 268.631 257.747L335.291 324.407C369.899 284.234 368.152 223.544 330.051 185.442L324.609 180L330.051 174.558C369.983 134.625 369.983 69.8815 330.051 29.9494C290.118 -9.98312 225.375 -9.98312 185.442 29.9494L180 35.3916Z"
        fill="#131313"
      />
      <path
        d="M324.407 335.291L257.747 268.631C214.809 225.693 145.191 225.693 102.253 268.631L91.369 257.747C134.307 214.809 134.307 145.192 91.369 102.254L24.7087 35.5934C-9.89901 75.766 -8.15245 136.456 29.9492 174.558L35.3915 180L29.9492 185.442C-9.98293 225.375 -9.98323 290.118 29.9492 330.051C69.8814 369.983 134.626 369.983 174.558 330.051L180 324.609L185.442 330.051C223.544 368.152 284.234 369.899 324.407 335.291Z"
        fill="#131313"
      />
      <path
        d="M210.321 210.321C193.575 227.068 166.425 227.068 149.679 210.321C132.932 193.575 132.932 166.426 149.679 149.679C166.425 132.932 193.575 132.932 210.321 149.679C227.068 166.426 227.068 193.575 210.321 210.321Z"
        fill="#131313"
      />
    </svg>
  );
};

export default SubtractIcon;
