import React, { useState } from 'react';

import PlanItem, { planPropTypes } from '../PlanItem';
import Tariffs from '../Tarifs/Tariffs';

const price = {
  annual: '$9,990',
  sixMonths: '$5,100',
  threeMonths: '$2,700',
  monthly: '$999',
};

const YearlyPlan = ({
  className,
  onAction,
  theme,
  textColor,
  actionBtnTheme,
  actionTitle = 'Subscribe',
  featuresTitle,
  features = [],
  ...props
}) => {
  const [selectedTariff, setSelectedTariff] = useState('annual');
  return (
    <PlanItem
      className={className}
      theme={theme}
      actionBtnTheme={actionBtnTheme}
      textColor={textColor}
      title="Job Posts + Database"
      tariffs={
        <Tariffs
          theme={theme}
          setSelectedTariff={setSelectedTariff}
          tabs={[
            { value: 'annual', text: '12 months' },
            { value: 'sixMonths', text: '6 months' },
            { value: 'threeMonths', text: '3 months' },
            { value: 'monthly', text: '1 months' },
          ]}
        />
      }
      description="FULL ACCESS"
      price={price[selectedTariff]}
      featuresTitle={featuresTitle}
      features={features}
      action={actionTitle}
      onAction={onAction(selectedTariff)}
      {...props}
    />
  );
};

YearlyPlan.propTypes = planPropTypes;

export default YearlyPlan;
