import map from 'ramda/es/map';
import join from 'ramda/es/join';
import filter from 'ramda/es/filter';
import propOr from 'ramda/es/propOr';
import compose from 'ramda/es/compose';
import isEmpty from 'ramda/es/isEmpty';
import transduce from 'ramda/es/transduce';
import isObject from 'lodash/isObject';

const joinValues = compose(
  join(','),
  map(item => {
    if (isObject(item)) {
      return propOr('', 'value')(item);
    }
    return item;
  }),
);
const toQueryParam = ([key, value]) => {
  if (isEmpty(value)) return;
  if (Array.isArray(value)) return `${key}=${joinValues(value)}`;

  return `${key}=${value}`;
};

const transducer = compose(filter(Boolean), map(toQueryParam));
const reducer = (acc, value = '') => `${acc}${acc && value && '&'}${value}`;
const formattedQueryParams = compose(transduce(transducer, reducer, ''), Object.entries);

export default params => {
  const query = formattedQueryParams(params);
  const url = `${location.pathname}${query && `?${query}`}`;

  window.history.pushState('', '', url);
};
