import { useContext } from 'react';
import JobCreationContext from '../context/JobCreationContext';

/**
 * @returns {{openJobCreationModal, closeJobCreationModal, isJobCreationModalOpen}}
 */

function useJobCreationContext() {
  return useContext(JobCreationContext);
}

export default useJobCreationContext;
