import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import styles from './styles.styl';

const cx = cn.bind(styles);

const Image = React.forwardRef(({ src, isIcon, src2x, className, ...other }, ref) => (
  <div
    className={cx(
      'Image',
      {
        Image__icon: isIcon,
      },
      className,
    )}
  >
    <img
      ref={ref}
      className={cx('Image__img')}
      {...other}
      srcSet={src2x ? `${src2x} 2x` : null}
      alt={src}
      src={src}
    />
  </div>
));

Image.displayName = 'forwardRefImage';

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isIcon: PropTypes.bool,
  src: PropTypes.string,
  src2x: PropTypes.string,
  className: PropTypes.string,
};

Image.defaultProps = {
  image: '',
  src: '',
  src2x: '',
  isIcon: false,
};

export default Image;
