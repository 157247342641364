import React from 'react';
import cn from 'classnames/bind';
import PropTypes from 'prop-types';

import Card from '../../ui/Card';
import useAnalytics from 'hooks/useAnalytics';

import styles from './styles.styl';

const cx = cn.bind(styles);

const PortfolioRow = ({
  index,
  listChunk,
  style,
  subscribed,
  showSubscriptionPopup,
  onFavoriteClick,
}) => {
  const { elementClick } = useAnalytics();
  const handleCardClick = e => {
    const ev = subscribed ? 'subscribed' : 'not-subscribed';
    elementClick('80lv_rfp_main_open-profile', ev);

    if (!subscribed) {
      showSubscriptionPopup(e);
    }
  };
  return (
    <div style={style} className={cx('PortfolioList__row')}>
      {listChunk[index].map(({ portfolio, profile, is_favorite, company }) => (
        <Card
          profile={profile}
          portfolio={portfolio}
          company={company}
          isFavorite={is_favorite}
          key={portfolio.domain}
          className={cx('PortfolioList__item')}
          subscribed={subscribed}
          onClick={handleCardClick}
          onFavoriteClick={onFavoriteClick}
        />
      ))}
    </div>
  );
};

PortfolioRow.propTypes = {
  keyRow: PropTypes.string,
  index: PropTypes.number.isRequired,
  listChunk: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  subscribed: PropTypes.bool,
  showSubscriptionPopup: PropTypes.func,
  onFavoriteClick: PropTypes.func,
};

export default PortfolioRow;
