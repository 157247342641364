import React from 'react';

const LeftArrowIcon = props => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29289 11.7071C6.90237 11.3166 6.90237 10.6834 7.29289 10.2929L13.2929 4.29289C13.6834 3.90237 14.3166 3.90237 14.7071 4.29289C15.0976 4.68342 15.0976 5.31658 14.7071 5.70711L9.41421 11L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L7.29289 11.7071Z"
    />
  </svg>
);

export default LeftArrowIcon;
