import React, { useEffect, useState } from 'react';
import { lockScroll, unlockScroll } from 'utils/scrollLock';
import { el, renderCategories, categoriesPropTypes } from './CategoryFiltersPanel';
import Button from 'features/rfp/ui/Button';

const CategoriesMenu = ({ categories, activeCategory, query }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => {
    setIsMenuOpen(false);
    unlockScroll();
  };
  const openMenu = () => {
    setIsMenuOpen(true);
    lockScroll();
  };

  useEffect(() => {
    return unlockScroll;
  }, []);

  if (isMenuOpen) {
    return (
      <div className={el('overlay')}>
        <div className={el()}>
          {renderCategories({ categories, activeCategory, onClick: closeMenu, query })}
        </div>
      </div>
    );
  } else {
    return (
      <div className={el()}>
        <Button className={el('button')} isUppercase={false} onClick={openMenu}>
          Select a skill
        </Button>
      </div>
    );
  }
};

CategoriesMenu.propTypes = categoriesPropTypes;

export default CategoriesMenu;
