import React from 'react';
import PropTypes from 'prop-types';

import Filler from '../../ui/Filler';
import TriangleIcon from '../../ui/icons/TriangleIcon';
import LookIcon from '../../ui/icons/LookIcon';
import Button from 'features/rfp/ui/Button';

import useFilters from 'features/rfp/hooks/useFilters';
import { useRouter } from 'next/dist/client/router';
import styles from './CompanyFiller.styl';
import bemCN from 'utils/bemCN';

const el = bemCN(styles)('CompanyFiller');

const CompanyFiller = ({ onSubscribe, subscribed, isCompanyInfoLoaded }) => {
  const { categories } = useFilters();
  const {
    query: { category },
  } = useRouter();

  const activeCategory = categories.find(c => c.slug === category);
  const title = activeCategory ? activeCategory.section_name : 'Find the right talent Right now';
  const description = activeCategory ? (
    activeCategory.description
  ) : (
    <>
      Search for top-tier digital talent <br /> and start the conversation
    </>
  );

  const renderActions = () => {
    if (!isCompanyInfoLoaded) {
      return <div className={el('container')}></div>;
    }

    if (subscribed) {
      return (
        <div className={el('container')}>
          <TriangleIcon />
        </div>
      );
    } else {
      return (
        <div className={el('container')}>
          <Button className={el('button')} onClick={onSubscribe} isUppercase={false}>
            Subscribe Now
          </Button>
        </div>
      );
    }
  };

  return (
    <Filler
      title={title}
      description={description}
      Icon={LookIcon}
      actions={renderActions()}
      absoluteImage
    />
  );
};

CompanyFiller.propTypes = {
  onSubscribe: PropTypes.func.isRequired,
  subscribed: PropTypes.bool.isRequired,
  isCompanyInfoLoaded: PropTypes.bool.isRequired,
};

export default CompanyFiller;
