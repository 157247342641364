import { useCallback, useMemo, useState } from 'react';
import chunk from 'lodash/chunk';
import debounce from 'lodash/debounce';

import { tablet, verticalTablet } from 'config/RFPbreakpoints';

const DELAY = 10;
const RATIO = 0.85;

const PHONE_COLUMN = 1;
const TABLET_COLUMN = 2;
const DESKTOP_COLUMN = 3;
const DEFAULT_ROW_WIDTH = 1288;

const GAP_WIDTH = 20;

const getWidthContainer = breakpoint => breakpoint - 2 * GAP_WIDTH;

const widthDesktopContainer = getWidthContainer(tablet);
const widthTabletContainer = getWidthContainer(verticalTablet);

const detectColumn = width => {
  if (width >= widthDesktopContainer) return DESKTOP_COLUMN;
  else if (width >= widthTabletContainer) return TABLET_COLUMN;
  else return PHONE_COLUMN;
};

const calculateHeight = (width, columnCount) => (width / columnCount) * RATIO;

const useVirtualizedList = list => {
  const [rowWidth, setRowWidth] = useState(DEFAULT_ROW_WIDTH);
  const setWidth = useCallback(
    debounce(width => setRowWidth(width), DELAY),
    [],
  );

  const columnCount = useMemo(() => detectColumn(rowWidth), [rowWidth]);
  const listChunk = useMemo(() => chunk(list, columnCount), [columnCount, list]);
  const isRowLoaded = useCallback(({ index }) => !!listChunk[index], [listChunk]);
  const rowCount = useMemo(() => Math.ceil(list.length / columnCount), [columnCount, list.length]);
  const rowHeight = useMemo(() => calculateHeight(rowWidth, columnCount), [columnCount, rowWidth]);

  return {
    rowCount,
    setWidth,
    rowHeight,
    listChunk,
    isRowLoaded,
  };
};

export default useVirtualizedList;
