import React from 'react';

const LookIcon = props => (
  <svg viewBox="0 0 416 345" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.9898 132.01L127.133 47.5126C136.325 33.3945 132.319 14.5055 118.187 5.32275C104.054 -3.85996 85.1466 0.141077 75.9534 14.2592L70.9898 21.8837V132.01ZM30.5256 -0.000244141C47.3844 -0.000244141 61.0512 13.6526 61.0512 30.4944V132.01H0V30.4944C0 13.6526 13.6668 -0.000244141 30.5256 -0.000244141ZM0 202.999C0 169.316 27.3336 141.948 61.0512 141.948H132.041C132.041 175.633 104.707 202.999 70.9898 202.999H61.0512V283.928H141.98V273.989C141.98 240.306 169.314 212.938 203.031 212.938V283.928C203.031 317.612 175.697 344.979 141.98 344.979H0V202.999ZM141.98 141.948H203.031V202.999C169.314 202.999 141.98 175.633 141.98 141.948ZM345.01 132.01L288.867 47.5126C279.675 33.3945 283.681 14.5055 297.813 5.32275C311.946 -3.85996 330.853 0.141077 340.046 14.2592L345.01 21.8837V132.01ZM385.474 -0.000244141C368.615 -0.000244141 354.949 13.6526 354.949 30.4944V132.01H416V30.4944C416 13.6526 402.333 -0.000244141 385.474 -0.000244141ZM416 202.999C416 169.316 388.666 141.948 354.949 141.948H283.959C283.959 175.633 311.293 202.999 345.01 202.999H354.949V283.928H274.02V273.989C274.02 240.306 246.686 212.938 212.969 212.938V283.928C212.969 317.612 240.303 344.979 274.02 344.979H416V202.999ZM274.02 141.948H212.969V202.999C246.686 202.999 274.02 175.633 274.02 141.948ZM119.264 232.816C119.264 239.089 114.178 244.174 107.905 244.174C101.632 244.174 96.5469 239.089 96.5469 232.816C96.5469 226.542 101.632 221.457 107.905 221.457C114.178 221.457 119.264 226.542 119.264 232.816ZM308.097 244.174C314.37 244.174 319.455 239.089 319.455 232.816C319.455 226.542 314.37 221.457 308.097 221.457C301.824 221.457 296.739 226.542 296.739 232.816C296.739 239.089 301.824 244.174 308.097 244.174Z"
    />
  </svg>
);

export default LookIcon;
