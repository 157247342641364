/* global XPayStationWidget */
import { useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';

import { getPSToken } from 'utils/apiMethods';
import useProfile from 'hooks/useProfile';
import useAuth from 'features/auth/hooks/useAuth';
import { getTokenData } from 'features/auth/utils/auth';
import { notifyError } from 'ui/Toast/utils';
import getData from './utils';
import useJobs from 'features/rfp/features/company/hooks/useJobs';

const SCRIPT_ID = 'paystation-script';

const usePayStation = ({ sandbox = false }) => {
  const { token: loginToken } = useAuth();
  const {
    formData: { name },
  } = useProfile();
  const { sub, email } = getTokenData(loginToken) || {};

  const purchaseStatusRef = useRef();

  const openWidget = useCallback(
    token => {
      if (XPayStationWidget) {
        XPayStationWidget.init({
          access_token: token,
          sandbox,
        });
        const { CLOSE, STATUS } = XPayStationWidget.eventTypes;

        XPayStationWidget.on(STATUS, (e, data) => {
          purchaseStatusRef.current = data.paymentInfo.status;
        });

        XPayStationWidget.on(CLOSE, () => {
          const timeoutId = setTimeout(() => {
            document.body.style = '';
            document.body.classList.remove('scroll-locked');
            clearTimeout(timeoutId);
          });
        });
        XPayStationWidget.open();
      }
    },
    [sandbox],
  );

  const openWithPlan = useCallback(
    async (plan, onClose) => {
      const data = getData({
        email,
        project_id: parseInt(process.env.GAMERS_XSOLLA_ID),
        name,
        sub,
        plan,
        sandbox,
      });

      try {
        const token = (await getPSToken(loginToken, data)).token;
        openWidget(token);
      } catch (error) {
        return notifyError({ error, message: error.errorMessage });
      }

      if (XPayStationWidget) {
        const { CLOSE } = XPayStationWidget.eventTypes;

        XPayStationWidget.on(CLOSE, () => {
          onClose && onClose(purchaseStatusRef.current);
        });
      }
    },
    [email, loginToken, name, openWidget, sandbox, sub],
  );

  const { publishJob } = useJobs();

  const buyJobPost = useCallback(
    async (jobId, onClose) => {
      try {
        const { data } = await Axios.post(
          'https://store.xsolla.com/api/v2/project/53226/payment/item/jobpost',
          {
            sandbox,
            settings: {
              ui: {
                theme: 'dark',
              },
            },
            locale: 'en',
          },
          { headers: { Authorization: `Bearer ${loginToken}` } },
        );
        openWidget(data.token);
      } catch (error) {
        return notifyError({ error, message: error.errorMessage });
      }

      if (XPayStationWidget) {
        const { STATUS_DONE, CLOSE } = XPayStationWidget.eventTypes;
        XPayStationWidget.on(STATUS_DONE, () => {
          publishJob(jobId);
        });
        XPayStationWidget.on(CLOSE, () => {
          onClose && onClose(purchaseStatusRef.current);
        });
      }
    },
    [loginToken, openWidget, publishJob, sandbox],
  );

  useEffect(() => {
    if (loginToken.length !== 0) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.id = SCRIPT_ID;
      s.async = true;
      s.src = 'https://static.xsolla.com/embed/paystation/1.0.7/widget.min.js';
      const head = document.getElementsByTagName('head')[0];
      if (!document.getElementById(SCRIPT_ID)) {
        head.appendChild(s);
      }
    }
  }, [loginToken]);

  return {
    openWithPlan,
    buyJobPost,
  };
};

export default usePayStation;
