import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import NextLink from 'components/NextLink';
import { RoutesRFP } from 'config/routes';
import Button from 'features/rfp/ui/Button';
import useAnalytics from 'hooks/useAnalytics';

import bemCN from 'utils/bemCN';
import styles from './styles.styl';

const el = bemCN(styles)('Paywall');

const Paywall = ({ onClick }) => {
  const { elementClick } = useAnalytics();
  const onCreateJobClick = useCallback(() => {
    elementClick('80lv_rfp_main_create-jobs');
  }, [elementClick]);

  return (
    <div className={el()}>
      <div className={el('shadow')} onClick={onClick} />
      <div className={el('box')}>
        <div className={el('content')}>
          <h3 className={el('title')}>
            Like these candidates?
            <br />
            <strong>There are many more to see!</strong>
          </h3>
          <div className={el('action-wrapper')}>
            <Button isUppercase={false} className={el('action')} onClick={onClick}>
              Subscribe Now
            </Button>
            <div className={el('action-description')}>
              <p>Find amazing creative talent for your game.</p>{' '}
              <p>Start the conversation today.</p>
            </div>
          </div>
          <div className={el('action-wrapper')}>
            <NextLink route={RoutesRFP.Jobs}>
              <Button isUppercase={false} className={el('action')} onClick={onCreateJobClick}>
                Create Job
              </Button>
            </NextLink>

            <div className={el('action-description')}>
              <p>Get responses from talent job-seekers</p> <p>who dream of working with you.</p>
            </div>
          </div>
        </div>
        <div>
          <img
            className={el('image')}
            src="/static/images/rfp/paywall-art.png"
            alt="art by Konstantin Gdalevich"
          />
        </div>
        <span className={el('image-author-sign')}>
          art by <b>Konstantin Gdalevich</b>
        </span>
      </div>
    </div>
  );
};

Paywall.propTypes = {
  onClick: PropTypes.func,
};

export default Paywall;
