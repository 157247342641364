const { get, post } = require('./api');
const decodeJwt = require('jsonwebtoken/decode');

module.exports = {
  getArticles: domain => get({ url: `/articles/domain/${domain}` }),
  getHealthcheck: () => get({ url: '/healthcheck' }),
  getPSToken: (jwt, token_data) =>
    post({
      url: `https://store.xsolla.com/api/v1/xsolla_login/paystation/token`,
      data: {
        token_data,
        xsolla_login_user_id: decodeJwt(jwt).sub,
      },
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }),
};
