import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { useRouter } from 'next/router';

import NextLink from 'components/NextLink';
import Button from 'features/rfp/ui/Button';
import ModernModal from 'features/rfp/ui/ModernModal';
import AppLink from 'components/AppLink';
import YearlyPlan from 'components/PlanItem/YearlyPlan';

import usePayStation from 'features/rfp/hooks/usePayStation';
import useAnalytics from 'hooks/useAnalytics';
import { RoutesRFP } from 'config/routes';
import bemCN from 'utils/bemCN';

import styles from './styles.styl';

const el = bemCN(styles)('PaywallPopup');

const plans = {
  annual: {
    id: 'hdvWGh1X',
    duration: '1y',
  },
  monthly: {
    id: '2bQytTzE',
    duration: '1m',
  },
  threeMonths: {
    id: 'F5bfcCPG',
    duration: '3m',
  },
  sixMonths: {
    id: 'mgeIiWDe',
    duration: '6m',
  },
};

function PopupContent({ onClose }) {
  const { query } = useRouter();
  const sandbox = Boolean(query['NnqAq76qH5Kgsandbox']);
  const { openWithPlan } = usePayStation({ sandbox });
  const { elementClick } = useAnalytics();

  const onPlanPayStationWidgetClose = useCallback(purchaseStatus => {
    if (purchaseStatus === 'done') {
      location && location.reload();
    }
  }, []);
  const handleSubscribeClick = planType => () => {
    const plan = plans[planType];
    openWithPlan(plan.id, onPlanPayStationWidgetClose);
    elementClick('80lv_rfp_tariffs_select-tariff', plan.duration);
  };

  return (
    <>
      <div className={el('features')}>
        <header className={el('header')}>
          <div className={el('title')}>Find the right talent, right now</div>
        </header>
        <p className={el('description')}>Subscribe for full access to all features</p>
        <YearlyPlan
          className={el('subcription')}
          featuresBottom
          featuresTitle="Full access includes:"
          features={[
            'Unlimited Job Posts',
            'A powerful mix of Job Posts, Talent Database and Questionnaire',
            'Promotional boosts across the 80.lv network',
            'Inclusion in a weekly Talent Digest of new jobs',
          ]}
          action="Subscribe"
          onAction={handleSubscribeClick}
        />

        <div className={el('job-post')}>
          <span>Want to publish a single job post?</span>
          <NextLink route={RoutesRFP.Jobs} onClick={onClose}>
            <Button className={el('job-post-button')} theme="black" isOutlined isUppercase={false}>
              View Jobs
            </Button>
          </NextLink>
        </div>
        <div className={el('pricing-page')}>
          See our{' '}
          <NextLink route={RoutesRFP.PricingPage} onClick={onClose}>
            Pricing page
          </NextLink>{' '}
          for details
        </div>
      </div>
    </>
  );
}

PopupContent.propTypes = {
  onClose: propTypes.func.isRequired,
};

function PopupFooter() {
  const subject = 'A question about 80 Level Talent';
  const consultationMailto = `mailto:talent@80.lv?subject=${subject}`;

  return (
    <>
      <p className={el('footer-text')}>
        <AppLink href={consultationMailto} targetBlank>
          Contact us
        </AppLink>
        with questions or comments
      </p>
    </>
  );
}

const PaywallPopup = ({ closeModal }) => {
  return (
    <ModernModal
      closeModal={closeModal}
      showClose
      closeClassName={el('close-btn')}
      content={<PopupContent onClose={closeModal} />}
      footer={<PopupFooter />}
    />
  );
};

PaywallPopup.propTypes = {
  closeModal: propTypes.func.isRequired,
};

export default PaywallPopup;
