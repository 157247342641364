import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames/bind';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/es/InfiniteLoader';
import List from 'react-virtualized/dist/es/List';
import WindowScroller from 'react-virtualized/dist/es/WindowScroller';

import PortfolioRow from './PortfolioRow';
import useVirtualizedList from '../../hooks/useVirtualizedList';
import Filler from 'features/rfp/ui/Filler';

import SubtractIcon from 'features/rfp/ui/icons/SubtractIcon';
import styles from './styles.styl';

const THRESHOLD = 1;
const cx = cn.bind(styles);

const PortfolioList = ({
  filters,
  subscribed,
  showSubscriptionPopup,
  popupRef,
  loadPortfolios,
  portfolios,
  preventLoadMore,
  onFavoriteClick,
}) => {
  const { isRowLoaded, listChunk, rowCount, rowHeight, setWidth } = useVirtualizedList(portfolios);

  const Row = useCallback(
    props => (
      <PortfolioRow
        showSubscriptionPopup={showSubscriptionPopup}
        subscribed={subscribed}
        listChunk={listChunk}
        onFavoriteClick={onFavoriteClick}
        {...props}
      />
    ),
    [showSubscriptionPopup, subscribed, listChunk, onFavoriteClick],
  );

  if (portfolios.length === 0) {
    return (
      <div ref={popupRef}>
        <Filler
          description={
            <>
              Oops, looks like we don&apos;t have any such profiles yet.
              <br /> Try changing some filters.
            </>
          }
          Icon={SubtractIcon}
          absoluteImage
        />
      </div>
    );
  }

  return (
    <>
      <InfiniteLoader
        threshold={THRESHOLD}
        rowCount={rowCount + 1}
        isRowLoaded={isRowLoaded}
        loadMoreRows={preventLoadMore ? () => {} : () => loadPortfolios(filters)}
      >
        {({ onRowsRendered, registerChild }) => (
          <WindowScroller ref={popupRef}>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
              <AutoSizer className={cx('PortfolioList__container')} disableHeight>
                {({ width }) => {
                  setWidth(width);
                  return (
                    <List
                      autoHeight
                      width={width}
                      height={height}
                      rowRenderer={Row}
                      ref={registerChild}
                      rowCount={rowCount}
                      rowHeight={rowHeight}
                      scrollTop={scrollTop > 0 ? scrollTop : null}
                      onScroll={onChildScroll}
                      isScrolling={isScrolling}
                      className={cx('PortfolioList')}
                      onRowsRendered={onRowsRendered}
                    />
                  );
                }}
              </AutoSizer>
            )}
          </WindowScroller>
        )}
      </InfiniteLoader>
    </>
  );
};

PortfolioList.propTypes = {
  filters: PropTypes.object,
  popupRef: PropTypes.object,
  subscribed: PropTypes.bool.isRequired,
  showSubscriptionPopup: PropTypes.func,
  loadPortfolios: PropTypes.func.isRequired,
  onFavoriteClick: PropTypes.func.isRequired,
  portfolios: PropTypes.array.isRequired,
  preventLoadMore: PropTypes.bool,
};

export default PortfolioList;
