import React from 'react';
import cn from 'classnames/bind';

import CompanyMainPage from 'features/rfp/components/CompanyMainPage';

import { RoutesRFP } from 'config/routes';
import { Roles, getRole } from 'features/auth/utils/roles';
import { getUserRolesFromTokenGroups, getTokenData } from 'features/auth/utils/auth';

import styles from './styles.styl';

const cx = cn.bind(styles);

const RFPMain = ({ cookieToken }) => {
  if (!cookieToken) {
    window.location.replace(RoutesRFP.Home.pattern);
    return null;
  }
  const data = getTokenData(cookieToken);
  const roles = getUserRolesFromTokenGroups(data.groups);
  const role = getRole(roles);

  if (role === Roles.Company) {
    return <CompanyMainPage className={cx('RFPMain')} />;
  } else {
    window.location.replace(RoutesRFP.Home.pattern);
    return null;
  }
};

export default RFPMain;
