import types from './types';
import QueryString from 'qs';

const PAGE_SIZE = 12;

const loadStart = () => ({
  type: types.LOAD_REQUEST,
  isLoading: true,
});

const loadSuccess = () => ({
  type: types.LOAD_SUCCESS,
  isLoaded: true,
  isLoading: false,
});

const loadFail = error => ({
  error,
  isLoading: false,
  type: types.LOAD_FAILURE,
});

const setPortfolios = portfolios => ({
  type: types.SET_PORTFOLIOS,
  portfolios,
});

const setOffset = offset => ({
  type: types.SET_OFFSET,
  offset,
});

const setFavorite = (domain, isFavorite) => ({
  type: types.SET_FAVORITE,
  isFavorite,
  domain,
});

export const clearPortfolios = () => ({
  type: types.CLEAR_PORTFOLIOS,
  portfolios: [],
});

export const updateFavoriteState = (domain, isFavorite) => async (dispatch, _, httpService) => {
  await httpService.post({
    url: '/rfp/company/portfolio/favorite',
    data: {
      domain,
      action: isFavorite ? 'add' : 'remove',
    },
  });
  dispatch(setFavorite(domain, isFavorite));
};

export const loadPortfolios = ({ count = PAGE_SIZE, isFilterRequest = false, ...filters } = {}) => (
  dispatch,
  getState,
  httpService,
) => {
  dispatch(loadStart());

  if (isFilterRequest) {
    dispatch(setOffset(0));
  }

  const { offset, items } = getState().rfpReducer.portfolios;
  if (items.length && !offset && !isFilterRequest) {
    dispatch(loadFail());
    return;
  }
  return httpService
    .get({
      url: '/rfp/portfolios',
      data: {
        count,
        offset,
        ...filters,
      },
      paramsSerializer: params =>
        QueryString.stringify(params, { arrayFormat: 'brackets', encode: false }),
    })
    .then(({ results = [], next = 0 }) => {
      if (isFilterRequest) {
        dispatch(clearPortfolios());
      }

      if (results.length > 0) {
        dispatch(loadSuccess());
        dispatch(setOffset(next));
        dispatch(setPortfolios(results));
      }
    })
    .catch(error => {
      dispatch(loadFail(error));
      // throw new Error(error.message);
    });
};
